import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage"; // New about page
import ProjectsPage from "./ProjectsPage"; // Assuming you have a projects page

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/about" element={<AboutPage />} /> {/* New about route */}
      </Routes>
    </Router>
  );
};

export default App;
