import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar"; // Reuse the NavBar component
import Footer from "./Footer"; // Add the Footer for consistency
import "./TextStyles.css";

const ContactPage = () => {
  return (
    <Container>
      <NavBar /> {/* Reuse the same navigation bar */}
      <MainContent>
        <ImageWrapper>
          <ProfileImage src="profile.png" alt="Profile" />
        </ImageWrapper>
        <TextContent>
          {/* Title with new title styles */}
          <h1 className="title-content title-green" >NEW CONNECTIONS</h1>
          {/* Text with specific font */}
          <p className="text-content">
            I’m a software engineer based in Colombia. I’ve contributed to the
            deployment, implementation, and maintenance of web applications at
            Quick.com.co, T-evolvers - Celsia, and Microsoft. In the past, I’ve
            been a back-end developer at Quick.com.co and a Software Development
            Engineer in Test at T-evolvers - Celsia. I hold a deep understanding
            of low-level languages and frameworks, optimizing performance and
            scalability. My work has spanned from supporting startups to working
            in a multinational tech giant, Microsoft, where I contributed to
            delivering quality software to millions of users.
          </p>
          <ContactButton href="mailto:andressantiagore@gmail.com?subject=Contact&body=Hi%20Andres,%0A%0A">
            Contact me
          </ContactButton>
        </TextContent>
      </MainContent>
      <Footer /> {/* Reuse the footer across all pages */}
    </Container>
  );
};

export default ContactPage;

// Styled components
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #2a2a2a;
  text-align: center;
  padding: 0; /* Remove all padding to prevent unwanted space */
  margin: 0; /* Ensure no margin at the top */
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem 5%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  max-width: 35%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  max-width: 300px;
`;

const TextContent = styled.div`
  max-width: 60%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const ContactButton = styled.a`
  display: inline-block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: #2a2a2a;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #555;
  }
`;
