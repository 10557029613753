import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import NavBar from "./NavBar";
import Footer from "./Footer"; // New Footer component
import "./TextStyles.css";

const ProjectsPage = () => {
  const scrollRef = useRef(null); // Define scrollRef
  const [showFade, setShowFade] = useState(false); // Define showFade and setShowFade

  const blogPosts = [
    {
      type: "Project",
      date: "Apr 17, 2020",
      title:
        "A project based on the command interpreter Bourne Shell (sh) with several differences in the structure and memory managemen",
      link: "https://github.com/andresvanegas19/simple_shell",
    },
    {
      type: "Hackaton",
      date: "May 31, 2020",
      title: "How to stay mentally healthy in quarantine?",
      link: "https://github.com/andresvanegas19/TechLabs-Project",
    },
    {
      type: "Hackaton",
      date: "Nov 29, 2020",
      title:
        "A model was developed to estimate the probability of a customer purchasing each brand, allowing for the assignment of the appropriate strategy.",
      link: "https://github.com/andresvanegas19/brewerydatacup",
    },
    {
      type: "Project",
      date: "May 2, 2022",
      title:
        "user expects a simple weekly message of the price and how likely it is to reach this price",
      link: "https://github.com/andresvanegas19/cass-andra",
    },
    {
      type: "Project",
      date: "Sep 10, 2024",
      title: "A replica of a PoS blockchain made in Rust",
      link: "https://github.com/andresvanegas19/rublock",
    },
    {
      type: "Blog",
      date: "Feb 5, 2020",
      title: "How the compilation in C works?",
      link: "https://medium.com/@andresreyesv/compilation-in-c-f80a7f0f4943",
    },
    {
      type: "Blog",
      date: "Jul 15, 2020",
      title: "How a SQL Database Engine Works?",
      link: "https://blog.devgenius.io/how-a-sql-database-engine-works-c319200889d7",
    },
    {
      type: "Blog",
      date: "Mar 24, 2022",
      title: "Performing automated data augmentation?",
      link: "https://medium.com/@andresreyesv/performing-automated-data-augmentation-379e002a15c5",
    },
    {
      type: "Blog",
      date: "Nov 23, 2023",
      title:
        "Stablecoins: Decoding 9 Years of Market Evolution and Their $7.4T Impact",
      link: "https://medium.com/@andresreyesv/stablecoins-decoding-9-years-of-market-evolution-and-their-7-4t-impact-37da23d38ff8",
    },
    {
      type: "Blog",
      date: "Aug 25, 2024",
      title: "Bitcoin Ordinals",
      link: "https://medium.com/@andresreyesv/bitcoin-ordinals-eaac574f63eb",
    },
    {
      type: "Blog",
      date: "Aug 29, 2020",
      title: "What happens when you type google.com under the hood?",
      link: "https://medium.com/@andresreyesv/what-happens-when-you-type-google-com-under-hood-e72ecab6edc3",
    },
    {
      type: "Project-MVP",
      date: "Jan 23, 2021",
      title:
        "Cost calculation within a route (currently for Colombia), the optimize route between an origin place and destination place, the tolls within a route, distance and duration of the trip",
      link: "https://github.com/SneyderHOL/Quick_Project",
    },
    {
      type: "Blog",
      date: "Dec 2, 2023",
      title: "Features of the Bitcoin mempool that make it special",
      link: "https://platzi.com/tutoriales/2735-bitcoin-developers/29715-caracteristicas-del-mempool-de-bitcoin-que-lo-hacen-especial/",
    },
  ];

  const sortedBlogPosts = blogPosts.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Scroll Event Listener
  const handleScroll = () => {
    const container = scrollRef.current;
    if (container) {
      const atBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      setShowFade(!atBottom); // Hide the fade when at the bottom
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Container>
      <NavBar />
      <MainContent>
        <LeftColumn>
          <h1 className="title-content title-yellow">CONTINUE BUILDING</h1>
          <ProjectImage src="builder.png" alt="Builder Image" />
        </LeftColumn>
        <RightColumn>
          <p className="text-content">
            From Concept to Creation: Scroll to See What I’ve Been Up To!
          </p>
          <ScrollableContainer ref={scrollRef}>
            <BlogList>
              {sortedBlogPosts.map((post, index) => (
                <BlogListItem key={index}>
                  <a href={post.link} target="_blank" rel="noopener noreferrer">
                    <em>{post.type} - </em>
                    <strong>{post.date} - </strong>
                    <span>{post.title}</span>
                  </a>
                </BlogListItem>
              ))}
            </BlogList>
          </ScrollableContainer>
          {showFade && <FadeEffect />}
        </RightColumn>
      </MainContent>
      <Footer /> {/* Add the Footer here */}
    </Container>
  );
};
export default ProjectsPage;

// Styled components
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #2a2a2a;
  text-align: center;
  padding: 0 1rem;
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem 5%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  max-width: 40%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }

  /* Adjust the spacing between title and image */
  h1 {
    margin-bottom: 0.5rem; /* Reduce space below the title */
  }
`;

const RightColumn = styled.div`
  max-width: 55%;
  text-align: left;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  max-width: 300px; /* Maximum width on larger screens */
  height: auto; /* Maintain aspect ratio */

  /* Make the size dynamic */
  @media (min-width: 768px) {
    max-width: 25vw; /* Dynamically scale based on viewport width */
  }

  @media (max-width: 768px) {
    max-width: 50vw; /* Dynamically scale on smaller screens */
    margin-top: 0.5rem;
  }
`;

// Scrollable container with fade-out effect
const ScrollableContainer = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
  padding-right: 1rem;
  position: relative;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  /* For Firefox */
  scrollbar-width: none;
`;

const BlogList = styled.ul`
  padding-left: 0;
`;

const BlogListItem = styled.li`
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  line-height: 1.8;
  list-style-type: none;
  margin-bottom: 0.25rem;

  a {
    text-decoration: none;
    color: #2a2a2a;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a:hover {
    color: #1da1f2;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FadeEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  pointer-events: none; /* Prevent interaction with the shadow */
`;
