import React from "react";
import styled from "styled-components";
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <SocialLink
          href="https://x.com/andres_vanml"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </SocialLink>
        <SocialLink
          href="https://github.com/andresvanegas19"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
        </SocialLink>
        <SocialLink
          href="https://www.linkedin.com/in/santiago-reyes/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </SocialLink>
      </SocialIcons>
    </FooterContainer>
  );
};

export default Footer;

// Styled components
const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 2rem;
  font-size: 2rem;
`;

const SocialLink = styled.a`
  color: #2a2a2a;
  transition: color 0.3s ease;

  &:hover {
    color: #1da1f2;
  }
`;
