import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const NavBar = () => {
  const location = useLocation(); // Get the current route

  return (
    <NavBarContainer>
      <NavItem className={location.pathname === "/" ? "active" : ""}>
        <Link to="/">MAIN</Link>
      </NavItem>
      <NavItem className={location.pathname === "/projects" ? "active" : ""}>
        <Link to="/projects">PROJECTS</Link>
      </NavItem>
      <NavItem className={location.pathname === "/about" ? "active" : ""}>
        <Link to="/about">ABOUT ME</Link>
      </NavItem>
    </NavBarContainer>
  );
};

export default NavBar;

// Styled components
const NavBarContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Slightly reduced gap between items */
  padding: 1rem 0; /* Vertical padding for space around the navbar */
  width: 100%;
`;

const NavItem = styled.div`
  text-transform: uppercase;
  font-size: 1.1rem; /* Slightly reduced font size to fit the screen better */
  font-weight: bold;
  padding: 0.4rem 1.2rem; /* Consistent padding inside each item */
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  a {
    color: #2a2a2a;
    text-decoration: none;
  }

  &:hover {
    background-color: #2a2a2a;
    a {
      color: white;
    }
  }

  &.active {
    background-color: #2a2a2a;
    a {
      color: white;
    }
  }
`;
