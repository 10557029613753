import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar"; // Reusable navigation component
import Footer from "./Footer"; // Reusable navigation component
import "./TextStyles.css";

const HomePage = () => {
  return (
    <Container>
      {/* Navigation */}
      <NavBar />
      {/* Main Section */}
      <MainSection>
        <TreeImage src="tree.png" alt="Tree Image" />
        <TextContent>
          <MainHeading>EDGER TO</MainHeading>
          <MainHeading className="title-yellow">CONTINUE BUILDING</MainHeading>
          <MainHeading className="title-green">NEW CONNECTIONS</MainHeading>
          <p className="text-content">
            I specialize in software development, focusing on the deployment,
            implementation, and maintenance of web and low-level applications.
            My aim is to optimize performance and scalability, delivering
            efficient solutions in both startup environments and large-scale
            multinational tech companies.
          </p>
        </TextContent>
      </MainSection>
      {/* Footer */}
      <Footer /> {/* Add the Footer here */}
    </Container>
  );
};

export default HomePage;

// Styled components
const Container = styled.div`
  font-family: "Arial", sans-serif;
  color: #2a2a2a;
  text-align: center;
  overflow-x: hidden; /* Ensure no horizontal overflow */
  width: 100%; /* Ensure it takes the full width */
`;

const MainSection = styled.div`
  display: flex;
  justify-content: center; /* Center the content */
  align-items: flex-start;
  padding: 2rem 5%; /* Add some padding but keep it tight */
  gap: 2rem; /* Smaller gap between tree and text */
  flex-wrap: wrap; /* Wrap content on smaller screens */
`;

const TreeImage = styled.img`
  width: 30vw; /* Adjust to fit within viewport */
  max-width: 400px; /* Ensure image doesn't exceed max width */
  flex-shrink: 0;
`;

const TextContent = styled.div`
  max-width: 600px; /* Keep text from overflowing */
  width: 100%; /* Make sure text takes up available space */
  text-align: left;
  flex-grow: 1; /* Ensure text can expand and fill space */

  @media (max-width: 768px) {
    text-align: center; /* Center text on smaller screens */
    padding-left: 1rem; /* Padding for smaller screens */
    padding-right: 1rem;
  }
`;

const MainHeading = styled.h1`
  font-size: clamp(2.5rem, 5vw, 5rem); /* Dynamically adjust heading size */
  font-weight: 900;
  font-family: "Alegreya Sans SC", sans-serif;
  text-transform: uppercase;
  line-height: 1.1;
  margin: 0;
  word-break: keep-all; /* Avoid breaking words awkwardly */
  text-align: left;

  @media (max-width: 768px) {
    text-align: center; /* Center headings on small screens */
  }
`;
